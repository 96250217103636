import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'
import { NavbarCategoryWrapper, NavbarCategoryWrapperProps } from './NavbarCategoryWrapper'
import './dropdown-container.less'
import { FeaturedCard, NavigationFeaturedCard } from './NavigationFeaturedCard'

interface DropdownContainerProps {
  variant?: 'dark' | 'light'
  title: string
  submenuList: NavbarCategoryWrapperProps['submenuList']
  featuredCard?: FeaturedCard
}

export const DropdownContainer = ({
  variant = 'dark',
  title,
  submenuList,
  featuredCard,
}: DropdownContainerProps) => {
  return (
    <>
      <div
        className={classNames('dropdown-container', {
          'dropdown-container--dark': variant === 'dark',
          'dropdown-container--light': variant === 'light',
        })}
        style={{
          zIndex: 110,
        }}
      >
        <div className="Page-container">
          <div className="dropdown-container__wrapper">
            {featuredCard && (
              <>
                <NavigationFeaturedCard
                  ctaHref={featuredCard.ctaLink.href}
                  ctaLabel={featuredCard.ctaLink.label}
                  description={featuredCard.description.description}
                  title={title}
                  imgAlt={featuredCard.image.description}
                  imgSrc={featuredCard.image.file.url}
                />
                <div
                  className={classNames('dropdown-container__divider', {
                    'dropdown-container__divider--dark': variant === 'dark',
                    'dropdown-container__divider--light': variant === 'light',
                  })}
                />
              </>
            )}

            <NavbarCategoryWrapper isDesktop submenuList={submenuList} variant={variant} />
          </div>
        </div>
      </div>
    </>
  )
}
